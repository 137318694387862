import { Type } from "class-transformer";

export interface IClickTrackingDTO {
    issuer: string;
    tenantId: string;
    element: string;
    start: string;
}

export interface IClickTrackingCumulativeDTO {
    issuer: string;
    tenantId: string;
    element: string;
    count: number;
}

export class StatisticDataOutputDTO {
    rawData: IClickTrackingDTO[];
    cumulativeData: IClickTrackingCumulativeDTO[];
}
