import { Type } from "class-transformer";

export interface IGroupUserCountDTO {
    name: string;
    users: number;
}

export interface IEnhancedBrowserCountDTO extends IGroupUserCountDTO {
    version: string;
}

// Users Over Time Statistic
export interface ITimePercentageStatistic {
    percentage: number;
    users: number;
}

// Participation Time Statistic
export interface IParticipationTime {
    time: string;
    users: number;
}

export interface ILosStatsSummary {
    usersOverall: number;
    averageLengthOfStay: number;
}

export class TimeRange {
    @Type(() => Date)
    start: Date;

    @Type(() => Date)
    end: Date;

    country: string;
}

export class UserLos {
    _id: string;
    email: string;

    @Type(() => TimeRange)
    trackingSessions: TimeRange[];
    durationSum: number;
}

export class StatisticDataOutputDTO {
    countries: IGroupUserCountDTO[];
    platforms: IGroupUserCountDTO[];
    browsers: IGroupUserCountDTO[];
    browsersEnhanced: IEnhancedBrowserCountDTO[];
    usersOverTime: ITimePercentageStatistic[];
    participationTime: IParticipationTime[];
    statsSummary: ILosStatsSummary;

    @Type(() => UserLos)
    usersLos: UserLos[];
}
