import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { StatisticDataOutputDTO } from "../../models/ClickStatistics";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";

export interface IClickStatisticConfigService {
    clickStatisticsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const ClickStatisticConfigService = new InjectionToken<IClickStatisticConfigService>("ClickStatisticConfig");

@Injectable()
export class ClickStatisticsService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(ClickStatisticConfigService) private clickStatisticConfig: IClickStatisticConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.clickStatisticConfig.utcDateFormat;
        this.datatableStateSaveMode = this.clickStatisticConfig.datatableStateSaveMode;
    }

    /**
     * ClickStatisticUser stuff following below...
     */
    public getIssuers(start: Date, end: Date): Observable<string[]> {
        const params: any = { start: start.toISOString(), end: end.toISOString() };

        return this.http.get<string[]>(this.getClickStatisticsServiceUrl(`issuers/`), {
            headers: this.oauthStorageAdapter.getJwtAuthHeadersJSON(),
            params: params as any
        });
    }

    public getTenants(issuer: string, start: Date, end: Date): Observable<string[]> {
        const params: any = { issuer: issuer, start: start.toISOString(), end: end.toISOString() };

        return this.http.get<string[]>(this.getClickStatisticsServiceUrl(`tenants/`), {
            headers: this.oauthStorageAdapter.getJwtAuthHeadersJSON(),
            params: params as any
        });
    }

    public getStatistics(tenantId: string, issuer: string, start: Date, end: Date): Observable<StatisticDataOutputDTO> {
        const params: any = { issuer: issuer, tenantId: tenantId, start: start.toISOString(), end: end.toISOString() };

        return this.http
            .get<StatisticDataOutputDTO>(this.getClickStatisticsServiceUrl(`stats/`), {
                headers: this.oauthStorageAdapter.getJwtAuthHeadersJSON(),
                params: params as any
            })
            .pipe(map((result) => plainToClass(StatisticDataOutputDTO, result)));
    }

    private getClickStatisticsServiceUrl(postfix: string): string {
        return this.clickStatisticConfig.clickStatisticsAPI + postfix;
    }
}
