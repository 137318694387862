import { HttpClient } from "@angular/common/http";
import { Injectable, InjectionToken, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { OAuthStorageAdapter } from "@impacgroup/angular-oauth-base";
import { BaseDatatableStateSaveMode } from "@impacgroup/angular-baselib";
import { StatisticDataOutputDTO } from "../../models/LosStatistics";
import { map } from "rxjs/operators";
import { plainToClass } from "class-transformer";

export interface ILosStatisticConfigService {
    losStatisticsAPI: string;
    utcDateFormat: string;
    datatableStateSaveMode: BaseDatatableStateSaveMode;
}

export const LosStatisticConfigService = new InjectionToken<ILosStatisticConfigService>("LosStatisticConfig");

@Injectable()
export class LosStatisticsService {
    public UTCDATEFORMAT: string = "";
    public datatableStateSaveMode: BaseDatatableStateSaveMode;

    constructor(@Inject(LosStatisticConfigService) private losStatisticConfig: ILosStatisticConfigService, private http: HttpClient, private oauthStorageAdapter: OAuthStorageAdapter) {
        this.UTCDATEFORMAT = this.losStatisticConfig.utcDateFormat;
        this.datatableStateSaveMode = this.losStatisticConfig.datatableStateSaveMode;
    }

    /**
     * LosStatisticUser stuff following below...
     */
    public getIssuers(start: Date, end: Date): Observable<string[]> {
        const params: any = { start: start.toISOString(), end: end.toISOString() };

        return this.http.get<string[]>(this.getLosStatisticsServiceUrl(`issuers/`), {
            headers: this.oauthStorageAdapter.getJwtAuthHeadersJSON(),
            params: params as any
        });
    }

    public getTenants(issuer: string, start: Date, end: Date): Observable<string[]> {
        const params: any = { issuer: issuer, start: start.toISOString(), end: end.toISOString() };

        return this.http.get<string[]>(this.getLosStatisticsServiceUrl(`tenants/`), {
            headers: this.oauthStorageAdapter.getJwtAuthHeadersJSON(),
            params: params as any
        });
    }

    public getScreenNames(tenantId: string, issuer: string, start: Date, end: Date): Observable<string[]> {
        const params: any = { issuer: issuer, tenantId: tenantId, start: start.toISOString(), end: end.toISOString() };

        return this.http.get<string[]>(this.getLosStatisticsServiceUrl(`screens/`), {
            headers: this.oauthStorageAdapter.getJwtAuthHeadersJSON(),
            params: params as any
        });
    }

    public getCountries(tenantId: string, issuer: string, start: Date, end: Date): Observable<string[]> {
        const params: any = { issuer: issuer, tenantId: tenantId, start: start.toISOString(), end: end.toISOString() };

        return this.http.get<string[]>(this.getLosStatisticsServiceUrl(`countries/`), {
            headers: this.oauthStorageAdapter.getJwtAuthHeadersJSON(),
            params: params as any
        });
    }

    public getStatistics(tenantId: string, issuer: string, start: Date, end: Date, screenName?: string, countryCode?: string, minMinutes?: number, domainsToIgnore?: string, domainsToShowOnly?: string): Observable<StatisticDataOutputDTO> {
        let params: any = { issuer: issuer, tenantId: tenantId, start: start.toISOString(), end: end.toISOString() };
        if (screenName) {
            params = {
                ...params,
                screenName: screenName
            };
        }
        if (countryCode) {
            params = {
                ...params,
                countryCode: countryCode
            };
        }
        if (minMinutes) {
            params = {
                ...params,
                minMinutes: minMinutes
            };
        }
        if (domainsToIgnore) {
            params = {
                ...params,
                domainsToIgnore: domainsToIgnore
            };
        }
        if (domainsToShowOnly) {
            params = {
                ...params,
                domainsToShowOnly: domainsToShowOnly
            };
        }

        return this.http
            .get<StatisticDataOutputDTO>(this.getLosStatisticsServiceUrl(`stats/`), {
                headers: this.oauthStorageAdapter.getJwtAuthHeadersJSON(),
                params: params as any
            })
            .pipe(map((result) => plainToClass(StatisticDataOutputDTO, result)));
    }

    // public detailTest(id: string): Observable<LosStatisticUserListOutputDTO[]> {
    //     return this.http
    //         .get<Object[]>(this.getLosStatisticsServiceUrl(`${id}/users/`), {
    //             headers: this.oauthStorageAdapter.getAuthHeadersJSON()
    //         })
    //         .pipe(map((result) => plainToClass(LosStatisticUserListOutputDTO, result)));
    // }

    private getLosStatisticsServiceUrl(postfix: string): string {
        return this.losStatisticConfig.losStatisticsAPI + postfix;
    }
}
