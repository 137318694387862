import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { ChartistModule } from "ng-chartist";

import { ClickStatisticsComponent } from "./clickstatistics.component";
import { ClickStatisticsService, ClickStatisticConfigService, IClickStatisticConfigService } from "./clickstatistics.service";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { ClickStatisticsMainComponent } from "./clickstatistics.main.component";

@NgModule({
    declarations: [ClickStatisticsMainComponent, ClickStatisticsComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule, BsDatepickerModule.forRoot(), TimepickerModule.forRoot(), ChartistModule],
    exports: [],
    providers: []
})
export class ClickStatisticsModule {
    static forRoot(clickStatisticConfig: IClickStatisticConfigService): ModuleWithProviders<ClickStatisticsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: ClickStatisticsModule,
            providers: [
                ClickStatisticsService,
                {
                    provide: ClickStatisticConfigService,
                    useValue: clickStatisticConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: ClickStatisticsMainComponent,
                children: [
                    {
                        path: "",
                        component: ClickStatisticsComponent
                    }
                ]
            }
        ];
    }
}
