<div class="content__inner" *appHasPermission="'CLICKSTATISTICS.READ'">
    <header class="content__title">
        <h1>{{ 'clickstatistics.title' | translate }}</h1>
        <small>{{ 'clickstatistics.subtitle' | translate }}</small>
    </header>

    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ 'clickstatistics.filter.card.title' | translate }}</h4>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'clickstatistics.filter.card.startdate' | translate }}</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input class="form-control" 
                                    type="text"
                                    placeholder="Pick a start date" 
                                    bsDatepicker 
                                    (bsValueChange)="refreshIssuers()"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }" 
                                    [(ngModel)]="startDate"
                                >
                            </div>
                            <div class="col-sm-4 time-picker-container right-of-datepicker">
                                <timepicker class="time-picker" 
                                    [showMeridian]="false" 
                                    [(ngModel)]="startDate"
                                ></timepicker>
                            </div>
                        </div>
                        <i class="form-group__bar"></i>
                        
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'clickstatistics.filter.card.enddate' | translate }}</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input class="form-control" 
                                type="text"
                                    placeholder="Pick a end date" 
                                    bsDatepicker 
                                    (bsValueChange)="refreshIssuers()"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }" 
                                    [(ngModel)]="endDate"
                                >
                            </div>
                            <div class="col-sm-4" class="time-picker-container right-of-datepicker">
                                <timepicker class="time-picker" 
                                    [showMeridian]="false" 
                                    [(ngModel)]="endDate"
                                ></timepicker>
                            </div>
                        </div>
                        <i class="form-group__bar"></i>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'clickstatistics.filter.card.issuer' | translate }}</label>
                        <ng-select
                                [items]="issuers"
                                [disabled]="issuers === undefined || issuers.length === 0"
                                placeholder="{{ 'clickstatistics.filter.card.selectissuer' | translate }}"
                                (change)="refreshTenants()"
                                [(ngModel)]="selectedIssuer">
                        </ng-select>

                        <div class="form-text text-muted">{{ngSelectSelected | json}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'clickstatistics.filter.card.tenant' | translate }}</label>
                        <ng-select
                                [items]="tenants"
                                [disabled]="tenants === undefined || tenants.length === 0"
                                placeholder="{{ 'clickstatistics.filter.card.selecttenant' | translate }}"
                                (change)="refreshScreenNamesAndRawData()"
                                [(ngModel)]="selectedTenant">
                        </ng-select>

                        <div class="form-text text-muted">{{ngSelectSelected | json}}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-auto d-flex flex-column justify-content-center">
                    <button type="button" class="btn btn-primary" (click)="showStatistics()" [disabled]="!startDate || !endDate || !selectedIssuer || !selectedTenant">Show</button>
                </div>
                <div class="col-auto d-flex flex-column justify-content-center">
                    <button type="button" class="btn btn-warning" (click)="resetInputs()">Reset</button>
                </div>
            </div>
        </div>
    </div>

    <!-- CumulativeData section -->
    <div class="row" *ngIf="cumulativeDataRows.length > 0">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ 'clickstatistics.cumulativeDatasection.cumulativeData' | translate }}</h4>
                    <div class="actions dropdown" dropdown placement="bottom right">
                        <button dropdownToggle type="button" class="btn" aria-controls="dropdown-basic">
                            <i class="zmdi zmdi-more-vert"></i>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><button class="dropdown-item" (click)="cumulativeDataCSVExport()">{{ 'clickstatistics.cumulativeDatasection.csvexport' | translate }}</button></li>
                        </ul>
                    </div>
                    <table datatable class="table table-bordered" id="table-cumulativeData" [dtOptions]="cumulativeDataDtOptions" [dtTrigger]="cumulativeDataDtTrigger">
                        <thead>
                            <tr>
                                <th>{{ 'clickstatistics.cumulativeDatasection.issuer' | translate }}</th>
                                <th>{{ 'clickstatistics.cumulativeDatasection.tenantId' | translate }}</th>
                                <th>{{ 'clickstatistics.cumulativeDatasection.element' | translate }}</th>
                                <th>{{ 'clickstatistics.cumulativeDatasection.count' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of cumulativeDataRows">
                                <td>{{ row.issuer }}</td>
                                <td>{{ row.tenantId }}</td>
                                <td>{{ row.element }}</td>
                                <td>{{ row.count }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- RawData section -->
    <div class="col-md-12" *ngIf="rawDataRows.length > 0">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ 'clickstatistics.rawDatasection.rawData' | translate }}</h4>
                <div class="actions dropdown" dropdown placement="bottom right">
                    <button dropdownToggle type="button" class="btn" aria-controls="dropdown-basic">
                        <i class="zmdi zmdi-more-vert"></i>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem"><button class="dropdown-item" (click)="rawDataCSVExport()">{{ 'clickstatistics.rawDatasection.csvexport' | translate }}</button></li>
                    </ul>
                </div>

                <table datatable class="table table-bordered" id="table-rawData" [dtOptions]="rawDataDtOptions" [dtTrigger]="rawDataDtTrigger">
                    <thead>
                        <tr>
                            <th>{{ 'clickstatistics.rawDatasection.issuer' | translate }}</th>
                            <th>{{ 'clickstatistics.rawDatasection.tenantId' | translate }}</th>
                            <th>{{ 'clickstatistics.rawDatasection.element' | translate }}</th>
                            <th>{{ 'clickstatistics.rawDatasection.start' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rawDataRows">
                            <td>{{ row.issuer }}</td>
                            <td>{{ row.tenantId }}</td>
                            <td>{{ row.element }}</td>
                            <td>{{ row.start }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>