<div class="content__inner" *appHasPermission="'LOSSTATISTICS.READ'">
    <header class="content__title">
        <h1>{{ 'losstatistics.title' | translate }}</h1>
        <small>{{ 'losstatistics.subtitle' | translate }}</small>
    </header>

    <div class="card">
        <div class="card-body">
            <h4 class="card-title">{{ 'losstatistics.filter.card.title' | translate }}</h4>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.startdate' | translate }}</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input class="form-control" 
                                    type="text"
                                    placeholder="Pick a start date" 
                                    bsDatepicker 
                                    (bsValueChange)="refreshIssuers()"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }" 
                                    [(ngModel)]="startDate"
                                >
                            </div>
                            <div class="col-sm-4 time-picker-container right-of-datepicker">
                                <timepicker class="time-picker" 
                                    [showMeridian]="false" 
                                    [(ngModel)]="startDate"
                                ></timepicker>
                            </div>
                        </div>
                        <i class="form-group__bar"></i>
                        
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.enddate' | translate }}</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <input class="form-control" 
                                type="text"
                                    placeholder="Pick a end date" 
                                    bsDatepicker 
                                    (bsValueChange)="refreshIssuers()"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showMeridian: false, containerClass: 'theme-blue' }" 
                                    [(ngModel)]="endDate"
                                >
                            </div>
                            <div class="col-sm-4" class="time-picker-container right-of-datepicker">
                                <timepicker class="time-picker" 
                                    [showMeridian]="false" 
                                    [(ngModel)]="endDate"
                                ></timepicker>
                            </div>
                        </div>
                        <i class="form-group__bar"></i>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.issuer' | translate }}</label>
                        <ng-select
                                [items]="issuers"
                                [disabled]="issuers === undefined || issuers.length === 0"
                                placeholder="{{ 'losstatistics.filter.card.selectissuer' | translate }}"
                                (change)="refreshTenants()"
                                [(ngModel)]="selectedIssuer">
                        </ng-select>

                        <div class="form-text text-muted">{{ngSelectSelected | json}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.tenant' | translate }}</label>
                        <ng-select
                                [items]="tenants"
                                [disabled]="tenants === undefined || tenants.length === 0"
                                placeholder="{{ 'losstatistics.filter.card.selecttenant' | translate }}"
                                (change)="refreshScreenNamesAndCountries()"
                                [(ngModel)]="selectedTenant">
                        </ng-select>

                        <div class="form-text text-muted">{{ngSelectSelected | json}}</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.screenname' | translate }}</label>
                        <ng-select
                                [items]="screenNames"
                                [disabled]="screenNames === undefined || screenNames.length === 0"
                                placeholder="{{ 'losstatistics.filter.card.selectscreenname' | translate }}"
                                [(ngModel)]="selectedScreenName">
                        </ng-select>

                        <div class="form-text text-muted">{{ngSelectSelected | json}}</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.country' | translate }}</label>
                        <ng-select
                                [items]="countries"
                                [disabled]="countries === undefined || countries.length === 0"
                                bindLabel="name"
                                placeholder="{{ 'losstatistics.filter.card.selectcountry' | translate }}"
                                [(ngModel)]="selectedCountry">
                        </ng-select>

                        <div class="form-text text-muted">{{ngSelectSelected | json}}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.minminutes' | translate }}</label>
                        <input type="number" class="form-control" placeholder="0" [(ngModel)]="minminutes">
                        <i class="form-group__bar"></i>
                    </div>
                </div>
                <div class="col-xs-12 col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.domains' | translate }}</label>
                        <input type="text" class="form-control" placeholder="" [(ngModel)]="domainsToIgnore">
                        <i class="form-group__bar"></i>
                    </div>
                </div>
                <div class="col-xs-12 col-md-4">
                    <div class="form-group">
                        <label>{{ 'losstatistics.filter.card.domainsToShowOnly' | translate }}</label>
                        <input type="text" class="form-control" placeholder="" [(ngModel)]="domainsToShowOnly">
                        <i class="form-group__bar"></i>
                    </div>
                </div>
                <div class="col-auto d-flex flex-column justify-content-center">
                    <button type="button" class="btn btn-primary" (click)="showStatistics()" [disabled]="!startDate || !endDate || !selectedIssuer || !selectedTenant">Show</button>
                </div>
                <div class="col-auto d-flex flex-column justify-content-center">
                    <button type="button" class="btn btn-warning" (click)="resetInputs()">Reset</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Country section -->
    <div class="row" *ngIf="countryRows.length > 0">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ 'losstatistics.countrysection.countries' | translate }}</h4>
                    <div class="actions dropdown" dropdown placement="bottom right" *ngIf="countryPieChartData?.series?.length > 0">
                        <button dropdownToggle type="button" class="btn" aria-controls="dropdown-basic">
                            <i class="zmdi zmdi-more-vert"></i>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><button class="dropdown-item" (click)="countryCSVExport()">{{ 'losstatistics.countrysection.csvexport' | translate }}</button></li>
                        </ul>
                    </div>
            
                    <div class="pie-chart-container">
                        <x-chartist 
                            *ngIf="countryPieChartData?.series?.length > 0"
                            type="Pie"
                            [data]="countryPieChartData"
                            [options]="countryPieChartOptions"
                            class="chart-pie"
                        ></x-chartist>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <table datatable class="table table-bordered" id="table-country" [dtOptions]="countryDtOptions" [dtTrigger]="countryDtTrigger">
                        <thead>
                            <tr>
                                <th>{{ 'losstatistics.countrysection.country' | translate }}</th>
                                <th>{{ 'losstatistics.countrysection.users' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of countryRows">
                                <td>{{ row.name }}</td>
                                <td>{{ row.users }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Platform section -->
    <div class="row" *ngIf="platformRows.length > 0">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ 'losstatistics.platformsection.platforms' | translate }}</h4>
                    <div class="actions dropdown" dropdown placement="bottom right" *ngIf="platformPieChartData?.series?.length > 0">
                        <button dropdownToggle type="button" class="btn" aria-controls="dropdown-basic">
                            <i class="zmdi zmdi-more-vert"></i>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><button class="dropdown-item" (click)="platformCSVExport()">{{ 'losstatistics.platformsection.csvexport' | translate }}</button></li>
                        </ul>
                    </div>
                    
                    <div class="pie-chart-container">
                        <x-chartist 
                            *ngIf="platformPieChartData?.series?.length > 0"
                            type="Pie"
                            [data]="platformPieChartData"
                            [options]="platformPieChartOptions"
                            class="chart-pie"
                        ></x-chartist>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <table datatable class="table table-bordered" id="table-platform" [dtOptions]="platformDtOptions" [dtTrigger]="platformDtTrigger">
                        <thead>
                            <tr>
                                <th>{{ 'losstatistics.platformsection.platform' | translate }}</th>
                                <th>{{ 'losstatistics.platformsection.users' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of platformRows">
                                <td>{{ row.name }}</td>
                                <td>{{ row.users }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Browser section -->
    <div class="row" *ngIf="browserRows.length > 0">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ 'losstatistics.browsersection.browsers' | translate }}</h4>
                    <div class="actions dropdown" dropdown placement="bottom right" *ngIf="browserPieChartData?.series?.length > 0">
                        <button dropdownToggle type="button" class="btn" aria-controls="dropdown-basic">
                            <i class="zmdi zmdi-more-vert"></i>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><button class="dropdown-item" (click)="browserCSVExport()">{{ 'losstatistics.browsersection.csvexport' | translate }}</button></li>
                        </ul>
                    </div>

                    <div class="pie-chart-container">
                        <x-chartist 
                            *ngIf="browserPieChartData?.series?.length > 0"
                            type="Pie"
                            [data]="browserPieChartData"
                            [options]="browserPieChartOptions"
                            class="chart-pie"
                        ></x-chartist>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <table datatable class="table table-bordered" id="table-browser" [dtOptions]="browserDtOptions" [dtTrigger]="browserDtTrigger">
                        <thead>
                            <tr>
                                <th>{{ 'losstatistics.browsersection.browser' | translate }}</th>
                                <th>{{ 'losstatistics.browsersection.users' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of browserRows">
                                <td>{{ row.name }}</td>
                                <td>{{ row.users }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Users over time section -->
    <div class="row" *ngIf="statsSummaryRows.length > 0">
        
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ 'losstatistics.usersovertimesection.usersovertime' | translate }}</h4>
                    <div class="actions dropdown" dropdown placement="bottom right" *ngIf="usersOverTimeAreaChartData?.series?.length > 0">
                        <button dropdownToggle type="button" class="btn" aria-controls="dropdown-basic">
                            <i class="zmdi zmdi-more-vert"></i>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><button class="dropdown-item" (click)="usersOverTimeCSVExport()">{{ 'losstatistics.usersovertimesection.csvexport' | translate }}</button></li>
                        </ul>
                    </div>

                    <div class="area-chart-container">
                        <x-chartist 
                            *ngIf="usersOverTimeAreaChartData?.series?.length > 0"
                            type="Line"
                            [data]="usersOverTimeAreaChartData"
                            [options]="usersOverTimeAreaChartOptions"
                        ></x-chartist>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ 'losstatistics.statssummery.title' | translate }}</h4>
                    <table class="table table-bordered">
                        <tbody>
                            <tr *ngFor="let row of statsSummaryRows">
                                <td>{{ row.name | translate }}</td>
                                <td>{{ row.count }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Participation time -->
    <div class="row" *ngIf="statistics?.participationTime?.length > 0">
        
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ 'losstatistics.participationtimesection.participationtime' | translate }}</h4>

                    <div class="area-chart-container">
                        <x-chartist
                            *ngIf="participationtimeBarChartData?.series?.length > 0"
                            type="Bar"
                            [data]="participationtimeBarChartData"
                            [options]="participationtimeBarChartOptions"
                        ></x-chartist>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- User participation over time -->
    <div [class]="statistics?.usersLos?.length > 0 ? 'row' : 'row invisible'">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ 'losstatistics.userparticipationovertimesection.userparticipationovertime' | translate }}</h4>
                    <div class="actions dropdown" dropdown placement="bottom right">
                        <button dropdownToggle type="button" class="btn" aria-controls="dropdown-basic">
                            <i class="zmdi zmdi-more-vert"></i>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem"><button class="dropdown-item" (click)="usersOverTimeRawCSVExport()">{{ 'losstatistics.usersovertimesection.csvexportraw' | translate }}</button></li>
                            <li role="menuitem"><button class="dropdown-item" (click)="usersOverTimeDurationCSVExport()">{{ 'losstatistics.usersovertimesection.csvexportduration' | translate }}</button></li>
                        </ul>
                    </div>
                    
                    <div #upotCont class="canvas-chart-container">
                        <canvas #upot></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>