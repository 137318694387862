import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { ChartistModule } from "ng-chartist";

import { LosStatisticsComponent } from "./losstatistics.component";
import { LosStatisticsService, LosStatisticConfigService, ILosStatisticConfigService } from "./losstatistics.service";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { LosStatisticsMainComponent } from "./losstatistics.main.component";

@NgModule({
    declarations: [LosStatisticsMainComponent, LosStatisticsComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule, BsDatepickerModule.forRoot(), TimepickerModule.forRoot(), ChartistModule],
    exports: [],
    providers: []
})
export class LosStatisticsModule {
    static forRoot(losStatisticConfig: ILosStatisticConfigService): ModuleWithProviders<LosStatisticsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: LosStatisticsModule,
            providers: [
                LosStatisticsService,
                {
                    provide: LosStatisticConfigService,
                    useValue: losStatisticConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: LosStatisticsMainComponent,
                children: [
                    {
                        path: "",
                        component: LosStatisticsComponent
                    }
                ]
            }
        ];
    }
}
