import { Component, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ClickStatisticsService } from "./clickstatistics.service";
import { BaseComponent } from "@impacgroup/angular-baselib";
import { ClickStatisticsMainComponent } from "./clickstatistics.main.component";
import * as moment from "moment";
import ctLegends from "chartist-plugin-legend";
import ctTooltip from "@impacgroup/chartist-plugin-tooltip";
import { StatisticDataOutputDTO } from "src/app/models/ClickStatistics";
import { Papa, UnparseConfig } from "ngx-papaparse";
import { saveAs } from "file-saver";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";

@Component({
    selector: "app-clickstatistics",
    templateUrl: "./clickstatistics.component.html",
    styleUrls: ["clickstatistics.component.scss"]
})
export class ClickStatisticsComponent extends BaseComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    startDate: Date = moment().clone().startOf("month").toDate();
    endDate: Date = moment().clone().endOf("month").toDate();
    issuers: string[];
    selectedIssuer: string;
    tenants: string[];
    selectedTenant: string;
    statistics: StatisticDataOutputDTO;

    rawDataRows: Array<any> = [];
    cumulativeDataRows: Array<any> = [];

    papaUnparseOptions: UnparseConfig = {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: null //or array of strings
    };

    @ViewChildren(DataTableDirective)
    dtElements: QueryList<DataTableDirective>;

    rawDataDtTrigger: Subject<any> = new Subject<any>();
    cumulativeDataDtTrigger: Subject<any> = new Subject<any>();

    rawDataDtOptions: DataTables.Settings = {};
    cumulativeDataDtOptions: DataTables.Settings = {};

    constructor(private clickStatisticsService: ClickStatisticsService, parent: ClickStatisticsMainComponent) {
        super();
    }

    ngOnInit() {
        this.refreshIssuers();
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        this.rawDataDtTrigger.unsubscribe();
        this.cumulativeDataDtTrigger.unsubscribe();
    }

    public refreshIssuers() {
        // Early exit, if one date is invalid
        if (this.invalidDate()) return;

        const issuersSubscription = this.clickStatisticsService.getIssuers(this.startDate, this.endDate).subscribe((issuers) => {
            this.issuers = issuers;

            this.checkSelectedIssuer();
            this.refreshTenants();
        });

        this.subscriptions.push(issuersSubscription);
    }

    public refreshTenants() {
        // Early exit, if issuer is empty
        if (!this.selectedIssuer) {
            this.tenants = [];

            this.checkSelectedTenant();

            return;
        }

        const tenantsSubscription = this.clickStatisticsService.getTenants(this.selectedIssuer, this.startDate, this.endDate).subscribe((tenants) => {
            this.tenants = tenants;

            this.checkSelectedTenant();
        });

        this.subscriptions.push(tenantsSubscription);
    }

    public showStatistics() {
        const statisticsSubscription = this.clickStatisticsService.getStatistics(this.selectedTenant, this.selectedIssuer, this.startDate, this.endDate).subscribe((statistics) => {
            this.statistics = statistics;

            this.refreshChartsAndTables();
        });

        this.subscriptions.push(statisticsSubscription);
    }

    public resetInputs() {
        this.startDate = moment().clone().startOf("month").toDate();
        this.endDate = moment().clone().endOf("month").toDate();
        this.selectedIssuer = undefined;
        this.selectedTenant = undefined;
    }

    // Exports
    public async rawDataCSVExport() {
        const csvArr: string[][] = [["issuer", "tenantId", "element", "start"]];
        const entries = this.statistics.rawData;

        for (const entry of entries) {
            csvArr.push([String(entry.issuer), String(entry.tenantId), String(entry.element), moment(entry.start).format("YYYY-MM-DDTHH-mm-ss")]);
        }

        const dataString = new Papa().unparse(csvArr, this.papaUnparseOptions);

        const blob = new Blob([dataString], { type: "text/csv;charset=utf-8" });
        const fileName = "rawdata_" + moment().format("YYYY-MM-DDTHH-mm-ss") + ".csv";
        saveAs(blob, fileName);
    }

    public async cumulativeDataCSVExport() {
        const csvArr: string[][] = [["issuer", "tenantId", "element", "count"]];
        const entries = this.statistics.cumulativeData;

        for (const entry of entries) {
            csvArr.push([String(entry.issuer), String(entry.tenantId), String(entry.element), String(entry.count)]);
        }

        const dataString = new Papa().unparse(csvArr, this.papaUnparseOptions);

        const blob = new Blob([dataString], { type: "text/csv;charset=utf-8" });
        const fileName = "cumulativedata_" + moment().format("YYYY-MM-DDTHH-mm-ss") + ".csv";
        saveAs(blob, fileName);
    }

    // Refresh all charts and tables
    private refreshChartsAndTables() {
        this.refreshRawData();
        this.refreshCumulativeData();
    }

    private refreshRawData() {
        this.refreshTable("rawData", "rawDataRows", "table-rawData", "rawDataDtTrigger");
    }

    private refreshCumulativeData() {
        this.refreshTable("cumulativeData", "cumulativeDataRows", "table-cumulativeData", "cumulativeDataDtTrigger");
    }

    private refreshTable(statisticsDataName, tableRows, dataTableId, dataTableTrigger) {
        if (tableRows !== null) {
            this[tableRows] = [];
        }

        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            const dtElRef = dtElement["el"] as ElementRef;

            // Check if it is the right table
            if (dtElRef.nativeElement.id !== dataTableId) {
                return;
            }

            // Check if data table instance exists
            if (typeof dtElement.dtInstance === "undefined") {
                return;
            }

            // If the data table already exists, destroy and create again
            dtElement.dtInstance.then((dtInstance: any) => {
                dtInstance.destroy();
            });
        });

        if (this.statistics?.[statisticsDataName]) {
            let data = this.statistics?.[statisticsDataName].map((item) => {
                if (item.name === null) {
                    item.name = "unknown";
                }
                return item;
            });

            if (tableRows !== null) {
                this[tableRows] = data;
            }
        }

        // Tick after Anglar show the elements
        setTimeout(() => {
            this[dataTableTrigger].next();
        });
    }

    // Check for invalid date
    private invalidDate() {
        const start = moment(this.startDate);
        const end = moment(this.endDate);

        return !start.isValid() || !end.isValid();
    }

    /**
     * Checks and resets selected issuer,  if it not exists in issuers
     */
    private checkSelectedIssuer() {
        if (this.issuers === undefined || !this.issuers.includes(this.selectedIssuer)) {
            this.selectedIssuer = undefined;
        }
    }

    /**
     * Checks and resets selected tenant,  if it not exists in tenants
     */
    private checkSelectedTenant() {
        if (this.tenants === undefined || !this.tenants.includes(this.selectedTenant)) {
            this.selectedTenant = undefined;
        }
    }
}
