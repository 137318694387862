import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { LosStatisticsService } from "./losstatistics.service";
import { AlertService, BaseComponent } from "@impacgroup/angular-baselib";
import { LosStatisticsMainComponent } from "./losstatistics.main.component";
import * as moment from "moment";
import { IPieChartOptions, IChartistData, IChartistAnimationOptions, ILineChartOptions, IBarChartOptions } from "chartist";
import { ChartEvent, ChartType } from "ng-chartist";
import ctLegends from "chartist-plugin-legend";
import ctTooltip from "@impacgroup/chartist-plugin-tooltip";
import { StatisticDataOutputDTO } from "src/app/models/LosStatistics";
import { StandardCountryService } from "src/app/global/services/country.service";
import { Papa, UnparseConfig } from "ngx-papaparse";
import { saveAs } from "file-saver";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { secondsToDhms, secondsToDhmsReadable } from "src/app/utils/timeutils";

@Component({
    selector: "app-losstatistics",
    templateUrl: "./losstatistics.component.html",
    styleUrls: ["losstatistics.component.scss"]
})
export class LosStatisticsComponent extends BaseComponent implements OnInit {
    public UTCDATEFORMAT: string = "";

    startDate: Date = moment().clone().startOf("month").toDate();
    endDate: Date = moment().clone().endOf("month").toDate();
    issuers: string[];
    selectedIssuer: string;
    tenants: string[];
    selectedTenant: string;
    screenNames: string[];
    selectedScreenName: string;
    standardCountries: Array<{ code: string; name: string }>;
    countries: Array<{ code: string; name: string }>;
    selectedCountry: { code: string; name: string };
    minminutes: number;
    domainsToIgnore: string;
    domainsToShowOnly: string;
    statistics: StatisticDataOutputDTO;

    countryPieChartOptions: IPieChartOptions = {};
    platformPieChartOptions: IPieChartOptions = {};
    browserPieChartOptions: IPieChartOptions = {};
    usersOverTimeAreaChartOptions: ILineChartOptions = { axisY: { onlyInteger: true } };
    participationtimeBarChartOptions: IBarChartOptions = {};

    participationtimeBarChartEvents: ChartEvent = {};

    countryPieChartData: IChartistData = { series: [], labels: [] };
    platformPieChartData: IChartistData = { series: [], labels: [] };
    browserPieChartData: IChartistData = { series: [], labels: [] };
    usersOverTimeAreaChartData: IChartistData = { series: [], labels: [] };
    participationtimeBarChartData: IChartistData = { series: [], labels: [] };

    countryRows: Array<any> = [];
    platformRows: Array<any> = [];
    browserRows: Array<any> = [];
    statsSummaryRows: Array<any> = [];

    papaUnparseOptions: UnparseConfig = {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: null //or array of strings
    };

    @ViewChildren(DataTableDirective)
    dtElements: QueryList<DataTableDirective>;

    countryDtTrigger: Subject<any> = new Subject<any>();
    platformDtTrigger: Subject<any> = new Subject<any>();
    browserDtTrigger: Subject<any> = new Subject<any>();

    countryDtOptions: DataTables.Settings = {};
    platformDtOptions: DataTables.Settings = {};
    browserDtOptions: DataTables.Settings = {};

    @ViewChild("upotCont", { static: false })
    upotCont: ElementRef<HTMLDivElement>;

    @ViewChild("upot", { static: false })
    upot: ElementRef<HTMLCanvasElement>;

    public upotContEl: HTMLDivElement;
    public upotContext: CanvasRenderingContext2D;

    constructor(
        private losStatisticsService: LosStatisticsService,
        private translateService: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private alertService: AlertService,
        private standardCountryService: StandardCountryService,
        parent: LosStatisticsMainComponent
    ) {
        super();
    }

    ngOnInit() {
        const countriesSubscription = this.standardCountryService.getAllCountries().subscribe((countries) => {
            this.standardCountries = countries;
        });
        this.subscriptions.push(countriesSubscription);
        this.refreshIssuers();
    }

    ngAfterViewInit(): void {
        this.upotContEl = this.upotCont.nativeElement;
        this.upotContext = this.upot.nativeElement.getContext("2d");
    }

    ngOnDestroy(): void {
        this.countryDtTrigger.unsubscribe();
        this.platformDtTrigger.unsubscribe();
        this.browserDtTrigger.unsubscribe();
    }

    public refreshIssuers() {
        // Early exit, if one date is invalid
        if (this.invalidDate()) return;

        const issuersSubscription = this.losStatisticsService.getIssuers(this.startDate, this.endDate).subscribe((issuers) => {
            this.issuers = issuers;

            this.checkSelectedIssuer();
            this.refreshTenants();
        });

        this.subscriptions.push(issuersSubscription);
    }

    public refreshTenants() {
        // Early exit, if issuer is empty
        if (!this.selectedIssuer) {
            this.tenants = [];

            this.checkSelectedTenant();
            this.refreshScreenNamesAndCountries();

            return;
        }

        const tenantsSubscription = this.losStatisticsService.getTenants(this.selectedIssuer, this.startDate, this.endDate).subscribe((tenants) => {
            this.tenants = tenants;

            this.checkSelectedTenant();
            this.refreshScreenNamesAndCountries();
        });

        this.subscriptions.push(tenantsSubscription);
    }

    public refreshScreenNamesAndCountries() {
        // If no tenant selected, reset all dependent values and selections
        if (!this.selectedTenant) {
            this.screenNames = undefined;
            this.checkSelectedScreenName();

            this.countries = undefined;
            this.checkSelectedCountry();

            return;
        }

        const screenNamesSubscription = this.losStatisticsService.getScreenNames(this.selectedTenant, this.selectedIssuer, this.startDate, this.endDate).subscribe((screenNames) => {
            this.screenNames = screenNames;

            this.checkSelectedScreenName();
        });

        this.subscriptions.push(screenNamesSubscription);

        const countriesSubscription = this.losStatisticsService.getCountries(this.selectedTenant, this.selectedIssuer, this.startDate, this.endDate).subscribe((countries) => {
            this.countries = countries.map((item) => {
                const country = this.standardCountries.find((country) => country.code == item);
                return { code: item, name: country.name };
            });

            this.checkSelectedCountry();
        });

        this.subscriptions.push(countriesSubscription);
    }

    public showStatistics() {
        const statisticsSubscription = this.losStatisticsService
            .getStatistics(this.selectedTenant, this.selectedIssuer, this.startDate, this.endDate, this.selectedScreenName, this.selectedCountry?.code, this.minminutes, this.domainsToIgnore, this.domainsToShowOnly)
            .subscribe((statistics) => {
                this.statistics = statistics;

                this.refreshChartsAndTables();
            });

        this.subscriptions.push(statisticsSubscription);
    }

    public resetInputs() {
        this.startDate = moment().clone().startOf("month").toDate();
        this.endDate = moment().clone().endOf("month").toDate();
        this.selectedIssuer = undefined;
        this.selectedTenant = undefined;
        this.selectedScreenName = undefined;
        this.selectedCountry = undefined;
        this.minminutes = undefined;
    }

    // Exports
    public async countryCSVExport() {
        const csvArr: string[][] = [["Country", "Count"]];
        const entries = this.statistics.countries;

        for (const entry of entries) {
            csvArr.push([String(entry.name), String(entry.users)]);
        }

        const dataString = new Papa().unparse(csvArr, this.papaUnparseOptions);

        const blob = new Blob([dataString], { type: "text/csv;charset=utf-8" });
        const fileName = "countries_" + moment().format("YYYY-MM-DDTHH-mm-ss") + ".csv";
        saveAs(blob, fileName);
    }

    public async platformCSVExport() {
        const csvArr: string[][] = [["Platform", "Count"]];
        const entries = this.statistics.platforms;

        for (const entry of entries) {
            csvArr.push([String(entry.name), String(entry.users)]);
        }

        const dataString = new Papa().unparse(csvArr, this.papaUnparseOptions);

        const blob = new Blob([dataString], { type: "text/csv;charset=utf-8" });
        const fileName = "platforms_" + moment().format("YYYY-MM-DDTHH-mm-ss") + ".csv";
        saveAs(blob, fileName);
    }

    public async browserCSVExport() {
        const csvArr: string[][] = [["Browser", "Count"]];
        const entries = this.statistics.browsers;

        for (const entry of entries) {
            csvArr.push([String(entry.name), String(entry.users)]);
        }

        const dataString = new Papa().unparse(csvArr, this.papaUnparseOptions);

        const blob = new Blob([dataString], { type: "text/csv;charset=utf-8" });
        const fileName = "browsers_" + moment().format("YYYY-MM-DDTHH-mm-ss") + ".csv";
        saveAs(blob, fileName);
    }

    public async usersOverTimeCSVExport() {
        const csvArr: string[][] = [["Percent", "Count"]];
        const entries = this.statistics.usersOverTime;

        for (const entry of entries) {
            csvArr.push([String(entry.percentage), String(entry.users)]);
        }

        const dataString = new Papa().unparse(csvArr, this.papaUnparseOptions);

        const blob = new Blob([dataString], { type: "text/csv;charset=utf-8" });
        const fileName = "users_over_time_" + moment().format("YYYY-MM-DDTHH-mm-ss") + ".csv";
        saveAs(blob, fileName);
    }

    public async usersOverTimeRawCSVExport() {
        const csvArr: string[][] = [["Email", "Start", "End", "Country"]];
        const entries = this.statistics.usersLos;

        for (const entry of entries) {
            for (const session of entry.trackingSessions) {
                const timeRange = session;
                csvArr.push([String(entry.email), String(timeRange?.start?.toISOString()), String(timeRange?.end?.toISOString()), String(timeRange?.country ?? "")]);
            }
        }

        const dataString = new Papa().unparse(csvArr, this.papaUnparseOptions);

        const blob = new Blob([dataString], { type: "text/csv;charset=utf-8" });
        const fileName = "users_over_time_" + moment().format("YYYY-MM-DDTHH-mm-ss") + ".csv";
        saveAs(blob, fileName);
    }

    public async usersOverTimeDurationCSVExport() {
        const csvArr: string[][] = [["Email", "Duration (sec.)", "Duration (dd:hh:mm:ss)", "Duration (human readable)"]];
        const entries = this.statistics.usersLos;

        for (const entry of entries) {
            csvArr.push([String(entry.email), String(entry?.durationSum ?? 0), String(secondsToDhms(entry?.durationSum ?? 0)), String(secondsToDhmsReadable(entry?.durationSum ?? 0))]);
        }

        const dataString = new Papa().unparse(csvArr, this.papaUnparseOptions);

        const blob = new Blob([dataString], { type: "text/csv;charset=utf-8" });
        const fileName = "users_duration_" + moment().format("YYYY-MM-DDTHH-mm-ss") + ".csv";
        saveAs(blob, fileName);
    }

    // Refresh all charts and tables
    private refreshChartsAndTables() {
        this.refreshCountries();
        this.refreshPlatforms();
        this.refreshBrowsers();
        this.refreshUsersOverTime();
        this.refreshStatsSummaryTable();
        this.refreshParticipationTime();
        this.refreshUserParticipationOverTime();
    }

    private refreshCountries() {
        this.refreshChartAndTable("countries", "countryPieChartData", "countryPieChartOptions", "countryRows", "table-country", "countryDtTrigger");
    }

    private refreshPlatforms() {
        this.refreshChartAndTable("platforms", "platformPieChartData", "platformPieChartOptions", "platformRows", "table-platform", "platformDtTrigger");
    }

    private refreshBrowsers() {
        this.refreshChartAndTable("browsers", "browserPieChartData", "browserPieChartOptions", "browserRows", "table-browser", "browserDtTrigger");
    }

    private refreshUsersOverTime() {
        this.usersOverTimeAreaChartData = { series: [], labels: [] };
        this.usersOverTimeAreaChartOptions = { height: "360px" };

        setTimeout(() => {
            if (this.statistics?.usersOverTime) {
                const seriesData = this.statistics.usersOverTime.map((item) => {
                    return item.users;
                });

                const labels = this.statistics.usersOverTime.map((item) => {
                    return item.percentage;
                });

                this.usersOverTimeAreaChartData = {
                    labels: labels,
                    series: [
                        {
                            name: "test",
                            data: seriesData
                        }
                    ]
                };

                this.usersOverTimeAreaChartOptions = {
                    height: "400px", // because there is no legend
                    chartPadding: {
                        left: -12,
                        bottom: -15
                    },
                    fullWidth: true,
                    low: 0,
                    showArea: true,
                    showPoint: false,
                    axisY: {
                        onlyInteger: true
                    }
                };
            }
        });
    }

    private refreshStatsSummaryTable() {
        if (this.statistics?.usersOverTime) {
            this.statsSummaryRows = [
                { name: "losstatistics.usersovertimesection.usersOverall", count: this.statistics.statsSummary.usersOverall },
                { name: "losstatistics.usersovertimesection.averageLengthOfStay", count: this.statistics.statsSummary.averageLengthOfStay }
            ];
        } else {
            this.statsSummaryRows = [];
        }
    }

    private refreshParticipationTime() {
        this.participationtimeBarChartData = { series: [], labels: [] };
        this.participationtimeBarChartOptions = { height: "360px" };

        setTimeout(() => {
            if (this.statistics?.usersOverTime) {
                const series = this.statistics.participationTime.map((item) => {
                    return {
                        name: item.time,
                        data: [item.users]
                    };
                });

                this.participationtimeBarChartData = { series: series };

                this.participationtimeBarChartOptions = {
                    height: "360px",
                    chartPadding: {
                        left: -12,
                        bottom: -15
                    },
                    stackBars: false,
                    onlyInteger: false,
                    plugins: [
                        ctLegends({
                            position: "bottom"
                        }),
                        ctTooltip({
                            valueTransform: function (value) {
                                return value;
                            }
                        })
                    ],
                    axisY: {
                        onlyInteger: true
                    }
                };
            }
        });
    }

    private refreshUserParticipationOverTime() {
        if (!this.statistics?.usersLos) {
            return;
        }

        const selectedStartDate = this.startDate.getTime();
        const selectedEndDate = this.endDate.getTime();
        const selectedDateRange = selectedEndDate - selectedStartDate;

        const userLos = this.statistics.usersLos;
        const userLosCount = userLos.length;

        const contWidth = this.upotContEl.clientWidth;
        const contHeight = userLosCount * 8 + 4;

        const visStart = 100;
        const visWidth = contWidth - visStart;

        const ctx = this.upotContext;

        ctx.canvas.width = contWidth;
        ctx.canvas.height = contHeight;

        for (let i = 0; i < userLosCount; i++) {
            const entry = userLos[i];
            const trackingCount = entry.trackingSessions.length;

            // Name
            ctx.textAlign = "right";
            ctx.font = "8px Arial";
            ctx.fillText(entry.email, visStart - 5, (i + 1) * 8);

            for (let j = 0; j < trackingCount; j++) {
                const tSession = entry.trackingSessions[j];

                if (tSession.start === null || tSession.end === null) continue;

                const startDate = tSession.start.getTime();
                const endDate = tSession.end.getTime();

                let startCoord = startDate - selectedStartDate;
                if (startCoord < 0) {
                    startCoord = 0;
                }
                startCoord = startCoord / selectedDateRange;

                if (startCoord > 1) {
                    startCoord = 1;
                }
                startCoord = visStart + startCoord * visWidth;

                let endCoord = endDate - selectedStartDate;
                if (endCoord < 0) {
                    endCoord = 0;
                }
                endCoord = endCoord / selectedDateRange;

                if (endCoord > 1) {
                    endCoord = 1;
                }
                endCoord = visStart + endCoord * visWidth;

                // Lines
                ctx.lineWidth = 7;
                ctx.strokeStyle = "#0000FF";
                ctx.beginPath();
                ctx.moveTo(startCoord, i * 8 + 6);
                ctx.lineTo(endCoord, i * 8 + 6);
                ctx.stroke();
            }
        }
    }

    private refreshChartAndTable(statisticsDataName, pieChartData, pieChartOptions, tableRows, dataTableId, dataTableTrigger) {
        this[pieChartData] = {
            series: [],
            labels: []
        };

        if (tableRows !== null) {
            this[tableRows] = [];
        }

        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            const dtElRef = dtElement["el"] as ElementRef;

            // Check if it is the right table
            if (dtElRef.nativeElement.id !== dataTableId) {
                return;
            }

            // Check if data table instance exists
            if (typeof dtElement.dtInstance === "undefined") {
                return;
            }

            // If the data table already exists, destroy and create again
            dtElement.dtInstance.then((dtInstance: any) => {
                dtInstance.destroy();
            });
        });

        if (this.statistics?.[statisticsDataName]) {
            let data = this.statistics?.[statisticsDataName].map((item) => {
                if (item.name === null) {
                    item.name = "unknown";
                }
                return item;
            });

            if (statisticsDataName === "countries") {
                data = this.statistics?.countries.map((item) => {
                    const country = this.standardCountries.find((country) => country.code == item.name);

                    if (country) {
                        item.name = country.name;
                    }
                    return item;
                });
            }

            if (tableRows !== null) {
                this[tableRows] = data;
            }

            const series = data.map((item) => {
                return {
                    name: item.name,
                    data: item.users
                };
            });

            const seriesSum = series.reduce((sum, current) => sum + current.data, 0);

            // Wait a tick for pie chart disappear
            setTimeout(() => {
                this[pieChartData] = {
                    series: series
                };

                this[pieChartOptions] = {
                    height: "360px",
                    showLabel: false,
                    startAngle: 270,
                    plugins: [
                        ctLegends({
                            position: "bottom"
                        }),
                        ctTooltip({
                            valueTransform: (value) => {
                                return ((value / seriesSum) * 100).toFixed(2) + "% (" + value + " / " + seriesSum + ")";
                            }
                        })
                    ]
                };
            });
        }

        // Tick after Anglar show the elements
        setTimeout(() => {
            this[dataTableTrigger].next();
        });
    }

    // Check for invalid date
    private invalidDate() {
        const start = moment(this.startDate);
        const end = moment(this.endDate);

        return !start.isValid() || !end.isValid();
    }

    /**
     * Checks and resets selected issuer,  if it not exists in issuers
     */
    private checkSelectedIssuer() {
        if (this.issuers === undefined || !this.issuers.includes(this.selectedIssuer)) {
            this.selectedIssuer = undefined;
        }
    }

    /**
     * Checks and resets selected tenant,  if it not exists in tenants
     */
    private checkSelectedTenant() {
        if (this.tenants === undefined || !this.tenants.includes(this.selectedTenant)) {
            this.selectedTenant = undefined;
        }
    }

    /**
     * Checks and resets selected screen name,  if it not exists in screen names
     */
    private checkSelectedScreenName() {
        if (this.screenNames === undefined || !this.screenNames.includes(this.selectedScreenName)) {
            this.selectedScreenName = undefined;
        }
    }

    /**
     * Checks and resets selected country, if it not exists in countries
     */
    private checkSelectedCountry() {
        if (this.countries === undefined || !this.countries.includes(this.selectedCountry)) {
            this.selectedCountry = undefined;
        }
    }
}
